<!--
 * @Author: yuwenwen
 * @Date: 2022-11-15 10:36:38
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 14:57:00
 * @FilePath: \nc-funeral-mobile\src\views\policy\list.vue
-->
<template>
  <div class="page-container page-container-100 complaint">
    <van-list
      v-model="paginationList.loading"
      :finished="paginationList.finished"
      finished-text="没有更多了"
      @load="onLoad"
      error-text="加载失败,点击重新加载"
    >
      <div
        v-for="(item, index) in paginationList.list"
        :key="index"
        @click="toDetails(item)"
      >
        <div class="complaint-datetime">{{ item.publishDate }}</div>
        <div class="policy-box">
          <img class="img" :src="item.images" />
          <div class="remark">
            {{ item.advTitle }}
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { getPolicyList } from "@/api/index.js";
import { getImagesUrl } from "@/utils/funeral.js";
export default {
  name: "PolicyList",
  data() {
    return {
     
      paginationList: {
        publishMode: 0,
        advTitle: "",
        pageNo: 1,
        pageSize: 10,
        total: 0, //总共多少条
        list: [], //本地list
        loading: false,
        finished: false,
        error: false,
      },
    };
  },
  created() {
    // publishMode 0 ：宣传、1：法规 、 2：服务指南 、 3：殡葬服务
    let { publishMode } = this.$route.query;
    this.paginationList.publishMode = publishMode;
    // this.getPolicyList();
  },
  mounted() {},
  methods: {
    // 获取政策列表
    async getPolicyList() {
      let cplList = this.paginationList.list;
      let params = {
        pageNo: this.paginationList.pageNo,
        pageSize: this.paginationList.pageSize,
        publishMode:this.paginationList.publishMode,
        advTitle:this.paginationList.advTitle,
      };
      let [err, res] = await this.$awaitWrap(getPolicyList(params));
      if (err) {
        return;
      }
      let data = res.result;
      if (data && data.records.length > 0) {
        console.log(data.records);
        for (let i = 0; i < data.records.length; i++) {
          let newObj = {
            ...data.records[i],
            images: getImagesUrl(data.records[i].images),
          };
          cplList.push(newObj);
        }
        console.log(cplList);
        this.paginationList.list = cplList;
        this.paginationList.total = data.total;
        this.paginationList.loading = false;
        if (this.paginationList.list.length >= data.total) {
          this.paginationList.finished = true; // 结束加载状态
        }
      } else {
        this.paginationList.finished = true;
      }
    },
    onLoad() {
      let timer = setTimeout(() => {
        if (this.paginationList.total == 0) {
          this.getPolicyList();
          this.paginationList.pageNo = this.paginationList.pageNo + 1;
          this.paginationList.finished && clearTimeout(timer);
        } else {
          let list = this.paginationList.list;
          let total = this.paginationList.total;
          if (list.length < total) {
            this.getPolicyList();
            this.paginationList.pageNo = this.paginationList.pageNo + 1;
            this.paginationList.finished && clearTimeout(timer);
          }
        }
      }, 100);
    },

    // 详情
    toDetails(item) {
      this.$router.push({ path: "/policy/details", query: { id: item.id } });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/index.less";
</style>